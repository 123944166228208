import ImageGallery from 'react-image-gallery';

import one from '../../../assets/img/login-carousel/01.jpg';
import two from '../../../assets/img/login-carousel/02.jpg';
import three from '../../../assets/img/login-carousel/03.jpg';
import four from '../../../assets/img/login-carousel/04.jpg';
import five from '../../../assets/img/login-carousel/05.jpg';
import six from '../../../assets/img/login-carousel/06.jpg';
import seven from '../../../assets/img/login-carousel/07.jpg';
import { WelcomeBanner } from '../welcomeBanner/WelcomeBanner';

const images = [one, two, three, four, five, six, seven].map((image) => {
  return {
    original: image.src,
    thumbnail: image.src,
  };
});
export const LoginImageSlider = () => {
  return (
    <>
      <div className="login__imageGallery">
        <ImageGallery
          items={images}
          showNav={false}
          lazyLoad
          showFullscreenButton={false}
          showPlayButton={false}
          autoPlay={false}
          slideInterval={3000}
        />
        <WelcomeBanner />
      </div>
    </>
  );
};
