import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

export const CountrySelect = ({ name, value, onChange }) => {
  const { data: locationsData } = useQuery(GET_SHIPPING_LOCATIONS_COUNTRIES);

  const locations =
    locationsData?.shippingLocations?.edges?.filter((i) => !i.node.parent)?.map((i) => i.node?.name) ?? [];

  return (
    <select className="input" name={name} onChange={onChange} value={value}>
      {locations.map((i) => (
        <option key={i} value={i}>
          {i}
        </option>
      ))}
    </select>
  );
};

export const GET_SHIPPING_LOCATIONS_COUNTRIES = gql`
  query GET_SHIPPING_LOCATIONS {
    shippingLocations {
      edges {
        node {
          pk
          name
          parent {
            id
            name
          }
        }
      }
    }
  }
`;
