import { useState } from 'react';
import { useAlerts } from '../../context/Alert2Context';
import { useCart } from '../../context/CartContext';

export const AddToCartButton = ({ product, customization, variation }) => {
  const [qty, setQty] = useState(1);
  const { warningAlert, successAlert } = useAlerts();

  const { addItem, removeItem } = useCart();

  const addToCart = () => {
    if (product?.isVariable && !customization) {
      warningAlert('Finish customizing this product before adding it to your cart');
      return;
    }

    if (!(Number(qty) > 0)) {
      warningAlert('Enter a valid quantity');
      return;
    }
    let cartItemId = addItem({
      product,
      data: customization,
      qty,
      variation: variation?.pk,
    });
    successAlert(
      'Item added to cart',
      () => {
        removeItem(cartItemId);
      },
      'Undo'
    );
  };

  return (
    <div className="product__quantity__input">
      <input className="input" type="number" min="1" value={qty} onChange={(e) => setQty(e.currentTarget.value)} />
      <button className="iconButton--greenBg" onClick={() => addToCart()}>
        <i className="fas fa-cart-plus fa-fw"></i>
      </button>
    </div>
  );
};
