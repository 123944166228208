import React from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

export const LocationSelect = ({ selectedCountry, name, value, onChange }) => {
  const { data: locationsData } = useQuery(GET_SHIPPING_LOCATIONS);
  const locations = locationsData?.shippingLocations?.edges?.filter((i) => !i.node.parent) ?? [];

  return (
    <select className="input input-with-label" name={name} onChange={onChange} value={value}>
      <option value="">State</option>
      {locations
        .map((i) => i.node)
        .filter((i) => i?.name === selectedCountry)
        .map(
          ({ pk, name, children: { edges: children = [] } }) =>
            (children.length === 0 && (
              <option key={pk} value={pk}>
                {name}
              </option>
            )) || (
              <React.Fragment key={name}>
                {children
                  .map((i) => i.node)
                  .map(({ name, pk }) => (
                    <option key={pk} value={pk}>
                      {name}
                    </option>
                  ))}
              </React.Fragment>
            )
        )}
    </select>
  );
};

export const GET_SHIPPING_LOCATIONS = gql`
  query GET_SHIPPING_LOCATIONS {
    shippingLocations {
      edges {
        node {
          pk
          name
          parent {
            id
            name
          }
          children {
            edges {
              node {
                pk
                name
              }
            }
          }
        }
      }
    }
  }
`;
