import { useLazyQuery, useQuery } from '@apollo/client';
import { TextField, Autocomplete } from '@mui/material';
import gql from 'graphql-tag';
import React, { useState } from 'react';

export const StoreSelectForm = ({ gotoPrevStep, closeModal }) => {
  const [formData, setFormData] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showEmailWillBeSentToYouStep, setShowEmailWillBeSentToYouStep] = useState(false);
  const [getAccessCodeViaEmailStep, setGetAccessCodeViaEmailStep] = useState(false);

  const [getAccessCode, { loading: gettingAccessCode }] = useLazyQuery(GET_ACCESS_CODE, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let code = data?.accessCode?.code;
      if (!getAccessCodeViaEmailStep && !code) {
        setFormSubmitted(false);
        return setGetAccessCodeViaEmailStep(true);
      }
      if (code) {
        closeModal();
        window.location = `/login?accessCode=${code}`;
      } else {
        setShowEmailWillBeSentToYouStep(true);
      }
    },
  });

  function handleSubmit() {
    setFormSubmitted(true);
    if (!formData.state?.id) {
      return;
    }
    let variables = {
      state: formData.state?.id,
      city: formData.city,
      storeName: formData.storeName,
    };
    if (getAccessCodeViaEmailStep && (!formData.name?.trim() || !formData.email?.trim() || !isEmail(formData.email))) {
      return;
    } else {
      variables.name = formData.name;
      variables.email = formData.email;
    }
    getAccessCode({
      variables,
    });
  }

  return (
    <>
      {!showEmailWillBeSentToYouStep && (
        <>
          {!getAccessCodeViaEmailStep && (
            <>
              <p className="textMargin">Help us identify the store.</p>
              <p className="required">If you can’t remember something, just leave it blank.</p>
              <br />
            </>
          )}
          {getAccessCodeViaEmailStep && (
            <>
              <p className="textMargin">Enter below details, we'll send you an email with an access code</p>
              <br />
            </>
          )}
          <div className="inputs">
            {!getAccessCodeViaEmailStep && (
              <>
                <StatesAutoComplete
                  label="S​tate or P​rovince"
                  value={formData.state}
                  onChange={(state) => setFormData({ ...formData, state })}
                />
                {formSubmitted && !formData.state && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '.9em',
                      marginTop: '.4em',
                    }}
                  >
                    Select a State
                  </div>
                )}
                <br />
                <TextField
                  disabled={!formData.state}
                  value={formData.city ?? ''}
                  onChange={(e) => setFormData({ ...formData, city: e.currentTarget.value })}
                  variant="outlined"
                  placeholder="City"
                />
                <br />
                <TextField
                  value={formData.storeName ?? ''}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      storeName: e.currentTarget.value,
                    })
                  }
                  variant="outlined"
                  placeholder="Store Name"
                />
                <br />
              </>
            )}
            {getAccessCodeViaEmailStep && (
              <>
                <TextField
                  value={formData.name ?? ''}
                  onChange={(e) => setFormData({ ...formData, name: e.currentTarget.value })}
                  variant="outlined"
                  placeholder="Name"
                />
                {formSubmitted && !formData.name && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '.9em',
                      marginTop: '.4em',
                    }}
                  >
                    Enter Name
                  </div>
                )}
                <br />
                <TextField
                  value={formData.email ?? ''}
                  onChange={(e) => setFormData({ ...formData, email: e.currentTarget.value })}
                  variant="outlined"
                  placeholder="Email"
                />
                {formSubmitted && (!formData.email || !isEmail(formData.email)) && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '.9em',
                      marginTop: '.4em',
                    }}
                  >
                    Enter a valid Email
                  </div>
                )}
                <br />
              </>
            )}
          </div>
        </>
      )}
      {showEmailWillBeSentToYouStep && <p className="textMargin">An email will be sent to you with an access code.</p>}
      <div className="buttons--spaceBetween">
        {!showEmailWillBeSentToYouStep && (
          <>
            <button className="textButton" onClick={gotoPrevStep}>
              <i className="fas fa-angle-left fa-fw"></i> Prev
            </button>
            <button className="textButton" onClick={handleSubmit}>
              {(gettingAccessCode && <i className="fa fa-spinner fa-fw fa-spin"></i>) || (
                <>
                  {(getAccessCodeViaEmailStep && 'Submit') || 'Next'}
                  <i className="fa fa-angle-right fa-fw"></i>
                </>
              )}
            </button>
          </>
        )}
        {showEmailWillBeSentToYouStep && (
          <>
            <button className="textButton" style={{ visibility: 'hidden' }}>
              <i className="fas fa-angle-left fa-fw"></i>
            </button>
            <button className="textButton" onClick={closeModal}>
              Ok <i className="fas fa-angle-right fa-fw"></i>
            </button>
          </>
        )}
      </div>
    </>
  );
};

const StatesAutoComplete = ({ label, onChange, value }) => {
  const [open, setOpen] = React.useState(false);
  const { data: statesData } = useQuery(GET_STATES);

  return (
    <Autocomplete
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      open={open}
      value={value ?? null}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option?.name === value.name}
      getOptionLabel={(option) => option?.name ?? ''}
      options={statesData?.states?.edges?.map((i) => i.node) ?? []}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
          }}
        />
      )}
    />
  );
};

export const GET_STATES = gql`
  query GET_STATES {
    states: stateAccessCodes {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const GET_ACCESS_CODE = gql`
  query GET_ACCESS_CODE($state: String!, $city: String, $storeName: String, $name: String, $email: Email) {
    accessCode: searchStateAccessCode(id: $state, city: $city, storeName: $storeName, name: $name, email: $email) {
      code
    }
  }
`;

function isEmail(email) {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
}
