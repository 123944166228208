import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const AccordionItem = ({ faq, onToggle, active }) => {
  return (
    <div className="accordion__item">
      <div className="accordion__item__header" onClick={onToggle}>
        <div className="accordion__item__header__text">{faq.header}</div>
        <div className="accordion__item__header__icon">
          {active ? <i className="fas fa-angle-up fa-fw"></i> : <i className="fas fa-angle-down fa-fw"></i>}
        </div>
      </div>
      <div
        className={classNames({
          'accordion__item__body': true,
          'accordion__item__body--open': active,
        })}
      >
        {faq.body}
      </div>
    </div>
  );
};

AccordionItem.propTypes = {
  faq: PropTypes.object,
  onToggle: PropTypes.func,
};
