import { createContext, useContext } from 'react';
import gql from 'graphql-tag';
import { v4 as uuid } from 'uuid';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useCurrentSite } from './CurrentSiteState';

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

export const CartContextEl = ({ children, initialProps }) => {
  const { accessCode } = useCurrentSite();

  const [cartItems = [], setCartItems] = useLocalStorage(`cartItems_${accessCode}`, []);

  const cartFunctions = {
    getItemVariation: (item) =>
      item.product.shape?.variations.edges.find(({ node }) => node.pk === item.variation)?.node,
    addItem: ({ product, data, qty, variation }) => {
      let existingItem = cartItems.find(
        (item) => item.product.pk === product.pk && item.data === data && item.variation === variation
      );

      if (existingItem) {
        existingItem.qty += parseInt(qty);
        setCartItems([...cartItems]);
        return existingItem.uuid;
      } else {
        const item = {
          uuid: uuid,
          product,
          data,
          qty: parseInt(qty),
          variation,
        };
        setCartItems([...cartItems, item]);
        return item.uuid;
      }
    },
    updateItem: (uuid, updatedItem) => {
      let itemsCopy = JSON.parse(JSON.stringify(cartItems));
      let idx = itemsCopy.findIndex((i) => i.uuid === uuid);
      itemsCopy[idx] = updatedItem;
      setCartItems(itemsCopy);
    },
    removeItem: (uuid) => {
      setCartItems(cartItems.filter((i) => i.uuid !== uuid));
    },
    clear: () => {
      setCartItems([]);
    },
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        ...cartFunctions,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
