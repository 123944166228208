import * as React from 'react';
import { useAlerts } from './AlertContext';
import Cookies from 'universal-cookie';
import { useRouter } from 'next/router';
import { uniqBy } from 'lodash';
import { useLazyQuery, useQuery, gql } from '@apollo/client';
import { useLocalStorage } from '../hooks/useLocalStorage';

const CurrentSiteContext = React.createContext({});

export const useCurrentSite = () => {
  return React.useContext(CurrentSiteContext);
};

const GET_STORE_WITH_ACCESS_CODE = gql`
  query GET_STORE_WITH_ACCESS_CODE($code: String!) {
    accessCode(code: $code) {
      id
      code
      notes
      ignoreDefaultFeatured
      password
      enableFreeJointShipping
    }
  }
`;

const GET_ENABLE_JOINT_FREE_SHIPPING_WITH_ACCESS_CODE = gql`
  query GET_ENABLE_JOINT_FREE_SHIPPING_WITH_ACCESS_CODE($code: String!) {
    accessCode(code: $code) {
      enableFreeJointShipping
    }
  }
`;

export const CurrentSiteState = ({ children, initialProps }) => {
  const [accessCode, setAccessCode] = React.useState(initialProps?.accessCode);

  const router = useRouter();
  const { errorAlert } = useAlerts();
  const [recentProducts = [], setRecentProducts] = useLocalStorage(`recent_items_${accessCode}`, []);

  const [getEnableFreeShipping, { data: { accessCode: { enableFreeJointShipping } = {} } = {} }] = useLazyQuery(
    GET_ENABLE_JOINT_FREE_SHIPPING_WITH_ACCESS_CODE,
    { fetchPolicy: 'network-only' }
  );

  const [loginWithAccessCode, { loading: loginUsingAccessCodeInProgress }] = useLazyQuery(GET_STORE_WITH_ACCESS_CODE, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const cookies = new Cookies();
      setAccessCode(data.accessCode.code);
      cookies.set('accessCode', data.accessCode.code, { path: '/' });
      router.replace(`/${data.accessCode.code}`);
    },
    onError: (error) => errorAlert('Invalid Access Code. Please try again.'),
  });

  React.useEffect(() => {
    if (!accessCode) return;
    getEnableFreeShipping({ variables: { code: accessCode } });
  }, [accessCode]);

  function setProductViewed(product) {
    let prods = [...recentProducts];
    prods.unshift(product);
    prods = uniqBy(prods, (i) => i.id);
    if (prods.length > 20) {
      prods.length = 20;
    }
    setRecentProducts(prods);
  }

  async function loginUsingAccessCode(code) {
    return await loginWithAccessCode({ variables: { code } });
  }

  return (
    <CurrentSiteContext.Provider
      value={{
        accessCode,
        enableFreeJointShipping,
        loginUsingAccessCode,
        loginUsingAccessCodeInProgress,
        recentProducts,
        setProductViewed,
      }}
    >
      {children}
    </CurrentSiteContext.Provider>
  );
};
