import ImageGallery from 'react-image-gallery';
import { useEffect, useState } from 'react';

export const ImageSlider = ({ images = [] }) => {
  const [sliderImages, setSliderImages] = useState(images.map((i) => ({ original: i, thumbnail: i })));
  useEffect(() => {
    setSliderImages(images.map((i) => ({ original: i, thumbnail: i })));
  }, [images]);

  return (
    <>
      <style jsx>{`
        .image-gallery :global(.image-gallery-thumbnail) {
          width: 96px;
          height: 64px;
          border-radius: 1px;
          border: solid 2px transparent;
        }
        .image-gallery :global(.image-gallery-thumbnail.active) {
          border: solid 2px #e08d8f;
        }
        .image-gallery :global(.image-gallery-thumbnail) :global(.image-gallery-thumbnail-inner) {
          height: 100%;
        }
        .image-gallery
          :global(.image-gallery-thumbnail)
          :global(.image-gallery-thumbnail-inner)
          :global(.image-gallery-thumbnail-image) {
          height: 100%;
          object-fit: cover;
        }
        .image-gallery :global(.image-gallery-thumbnail) + :global(.image-gallery-thumbnail) {
          margin-left: 2px;
        }
        .image-gallery :global(.image-gallery-thumbnails) {
          padding: 8px 0;
        }
        .image-gallery :global(.image-gallery-image) {
          height: min(40vh, 300px);
        }
      `}</style>
      <div className="image-gallery">
        <ImageGallery
          items={sliderImages}
          showNav={false}
          lazyLoad={false}
          showFullscreenButton={false}
          showPlayButton={false}
          autoPlay={false}
          slideInterval={3500}
        />
      </div>
    </>
  );
};
