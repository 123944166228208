import { Button, Snackbar } from '@mui/material';
import React, { createContext, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const Alert2Context = createContext();

export const useAlerts = () => {
  return useContext(Alert2Context);
};

export const Alert2ContextEl = ({ children }) => {
  const [alerts, setAlerts] = useState([]);

  function addAlert(message, actionText, action) {
    let alert = {
      id: uuidv4(),
      open: true,
      message,
      actionText,
      action,
    };
    setAlerts([...alerts, alert]);
    setTimeout(() => {
      alert.open = false;
      setAlerts([...alerts, alert]);
    }, 5000);
  }

  function successAlert(message = 'Success', action, actionText = 'Ok') {
    addAlert(message, actionText, action);
  }

  function errorAlert(message = 'Error') {
    addAlert(message, 'Ok');
  }

  function warningAlert(message = 'Warning') {
    addAlert(message, 'Ok');
  }

  function handleClose(alertId) {
    let alert = alerts.find((i) => i.id === alertId);
    if (alert) {
      alert.open = false;
      setAlerts([...alerts]);
    }
  }

  function handleAction(alertId) {
    let alert = alerts.find((i) => i.id === alertId);
    if (alert) {
      alert.open = false;
      setAlerts([...alerts]);
      if (alert.action) {
        alert.action();
      }
    }
  }

  return (
    <Alert2Context.Provider
      value={{
        successAlert,
        errorAlert,
        warningAlert,
      }}
    >
      {children}
      {alerts.map((alert) => (
        <Snackbar
          key={alert.id}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={alert.open}
          onClose={() => handleClose(alert.id)}
          message={alert.message}
          action={
            <React.Fragment>
              <Button color="secondary" size="small" onClick={() => handleAction(alert.id)}>
                {alert.actionText}
              </Button>
            </React.Fragment>
          }
        />
      ))}
    </Alert2Context.Provider>
  );
};
