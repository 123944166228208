import React from 'react';
import { LoginForm } from '../loginForm';

export const WelcomeBanner = () => {
  return (
    <div className="storeWelcome">
      <div className="welcome">
        <div className="topBanner">
          <h1>
            Welcome <span className="offset">to</span> EveryName
            <span className="offset">.com</span>
          </h1>
          <div className="paragraph">
            <p>We offer a large variety of quality gifts and souvenirs.</p>
            <p>We produce and ship your customized product fast!</p>
          </div>
        </div>
        <div className="accessCodeWrapper">
          <LoginForm />
        </div>
      </div>
    </div>
  );
};
