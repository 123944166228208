import React from 'react';
import styles from './ProductCarousel.module.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { RenderImage } from '../renderImage';
import { formatCurrrency } from '../currency';

export const ProductCarousel = ({ products = [] }) => {
  const router = useRouter();
  const settings = {
    dots: false,
    centerMode: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 1,
    arrows: false,
    centerPadding: '24px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="productCarousel">
      <h2 className={styles.header}>Recently Viewed</h2>
      {products?.length && (
        <Slider {...settings}>
          {products.map((product) => (
            <div key={product.name}>
              <div
                className={styles.imgWrapper}
                onClick={() => {
                  router.push('/[accessCode]/product/[id]', `/${router.query.accessCode}/product/${product.id}`);
                }}
              >
                <RenderImage productId={product.pk} view={'product'} height={96} />
              </div>
              <Link href={`/${router.query.accessCode}/product/${product.id}`} passHref>
                <a className={styles.link}>
                  <p className={styles.name}>{product.description}</p>
                  <div className={styles.price}>{formatCurrrency(product.shape?.defaultVariation?.getOnlinePrice)}</div>
                </a>
              </Link>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};
