import React from 'react';
import { ProductCarousel } from '../productCarousel';
import { useCurrentSite } from '../../context/CurrentSiteState';

export const RecentProducts = () => {
  const { recentProducts } = useCurrentSite();

  const [products, setProducts] = React.useState([]);

  React.useEffect(() => {
    setProducts(recentProducts.filter((i) => !!i));
  }, [recentProducts]);

  if (!products.length) {
    return null;
  }
  return <ProductCarousel products={products} />;
};
