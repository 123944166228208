import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { gql } from '@apollo/client';
import classNames from 'classnames';
import { useCurrentSite } from '../../context/CurrentSiteState';
import { useGetMenuItems } from '../../hooks/useGetMenuItems';
import { Loader } from '../loader';

export const ProductNavigator = () => {
  const { accessCode } = useCurrentSite();
  const { data, loading } = useGetMenuItems(accessCode);
  const menuItemsData = data?.menuItems || [];

  return (
    <div className="productNavigator">
      <div className="productNavigator__header">Browse Our Products</div>
      {!loading ? <NestedAccordionRoot menuItems={menuItemsData} /> : <Loader />}
    </div>
  );
};

export const NestedAccordionRoot = ({ menuItems = [] }) => {
  const router = useRouter();
  const rootCat = useMemo(() => {
    const root = { children: menuItems };
    const seletedCatId = parseInt(router?.query.id);
    if (router.pathname.indexOf('/category') === 0 && seletedCatId > 0) {
      setSelectedCatPath(root, seletedCatId);
    }
    return root;
  }, [menuItems, router?.query.id]);
  return <NestedAccordion data={rootCat} />;
};

export const NestedAccordion = ({ data, level = 0, isOpened, toggleOpen }) => {
  const router = useRouter();
  const children = data?.children?.edges?.map((i) => i.node) || [];
  const hasChildren = !!children?.length;
  const [openedIndex, setOpenedIndex] = useState(-1);

  useEffect(() => {
    if (root) {
      isOpened = true;
    }

    if (data.selected && toggleOpen) {
      toggleOpen(true);
    }
  }, [data.selected]);

  const toggleItemOpen = (index) => (forceSet) => {
    if (forceSet !== undefined) {
      setOpenedIndex(!forceSet ? -1 : index);
    } else {
      setOpenedIndex(index === openedIndex ? -1 : index);
    }
  };

  const navigate = () => {
    router.push(`/[accessCode]/category/[id]`, `/${router.query.accessCode}/category/${data.tags?.edges?.[0]?.node?.pk}`);
  };

  const handleOnClick = (e) => {
    if (hasChildren) {
      e.stopPropagation();
      toggleOpen();
    } else {
      navigate();
    }
  };

  const root = level === 0;

  if (!data) {
    return <Loader />;
  }

  return (
    <div className={level == 4 ? 'nestedAccordion__item__noBorder' : 'nestedAccordion__item'} key={data.id}>
      {(!root && (
        <div
          className={classNames('nestedAccordion__item__header', {
            nestedAccordion__item__header__selected: !!data.selected && !hasChildren,
          })}
          onClick={handleOnClick}
        >
          <div
            className={classNames(`nestedAccordion__item__header__text__lvl${level}`, {
              nestedAccordion__item__header__text__leaf: !hasChildren,
            })}
          >
            {data.title}
          </div>
          {hasChildren && (
            <div className="nestedAccordion__item__header__icon">
              {isOpened ? <i className="fas fa-angle-up fa-fw"></i> : <i className="fas fa-angle-down fa-fw"></i>}
            </div>
          )}
        </div>
      )) ||
        null}
      {children?.map((i, index) => (
        <div
          key={i.id}
          className={classNames({
            'nestedAccordion__item__body': true,
            'nestedAccordion__item__body--open': root || isOpened,
          })}
        >
          <NestedAccordion
            data={i}
            level={level + 1}
            isOpened={openedIndex === index}
            toggleOpen={toggleItemOpen(index)}
          />
        </div>
      ))}
    </div>
  );
};

function setSelectedCatPath(menuItem, selectedCatId) {
  if (isNaN(menuItem.tags?.edges)) return menuItem.selected;
  menuItem.selected = menuItem.tags?.edges[0]?.node?.pk === selectedCatId;
  if (!menuItem.selected && menuItem.children?.edges) {
    menuItem.children?.edges.forEach((i) => {
      if (setSelectedCatPath(i.node, selectedCatId)) {
        menuItem.selected = true;
      }
    });
  }
  return menuItem.selected;
}

export const GET_NAV_MENU_ITEMS = gql`
  query GET_NAV_MENU_ITEMS {
    menuItems(isRootNode: true) {
      edges {
        cursor
        node {
          id
          title
          isLeafNode
          children {
            edges {
              node {
                id
                title
                isLeafNode
                children {
                  edges {
                    node {
                      id
                      title
                      isLeafNode
                      children {
                        edges {
                          node {
                            id
                            title
                            isLeafNode
                            children {
                              edges {
                                node {
                                  id
                                  title
                                  isLeafNode
                                  tags {
                                    edges {
                                      node {
                                        pk
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            tags {
                              edges {
                                node {
                                  pk
                                }
                              }
                            }
                          }
                        }
                      }
                      tags {
                        edges {
                          node {
                            pk
                          }
                        }
                      }
                    }
                  }
                }
                tags {
                  edges {
                    node {
                      pk
                    }
                  }
                }
              }
            }
          }
          tags {
            edges {
              node {
                pk
              }
            }
          }
        }
      }
    }
  }
`;
