import { useRouter } from 'next/router';
import { formatCurrrency } from '../currency';
import { RenderImage } from '../renderImage';

export const ProductGrid = (props) => {
  const router = useRouter();
  const styles = {
    cursor: 'pointer',
  };

  function renderProductGridItems() {
    return props.products.map((prod, idx) => {
      let product = prod.node ? prod.node : prod;

      return (
        <div
          key={idx}
          className="storeProduct"
          onMouseEnter={() => router.prefetch('/[accessCode]/product/[id]', `/${router.query.accessCode}/product/${product.id}`)}
          onClick={() => router.push('/[accessCode]/product/[id]', `/${router.query.accessCode}/product/${product.id}`)}
          style={styles}
        >
          <div className="storeProduct__image">
            <RenderImage productId={product.pk} id={product.id} height={180} />
            {product.isVariable && (
              <div className="storeProduct__star">
                <i className="fas fa-star fa-fw starIcon"></i>
              </div>
            )}
          </div>
          <div className="storeProduct__name">{product.group?.name}</div>
          <div className="storeProduct__price">{formatCurrrency(product.shape?.defaultVariation?.getOnlinePrice)}</div>
        </div>
      );
    });
  }

  return <div className="productsList">{renderProductGridItems()}</div>;
};
