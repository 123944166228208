import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import CircularProgress from '@mui/material/CircularProgress';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import classNames from 'classnames';
import { useFormik } from 'formik';
import Image from 'next/image';
import { useCurrentSite } from '../../context/CurrentSiteState';
import stripeImg from '../../../assets/img/stripe.png';
import { useCart } from '../../context/CartContext';
import { LocationSelect, GET_SHIPPING_LOCATIONS } from '../locationSelect';
import { CountrySelect } from '../countrySelect';

const CARD_ELEMENT_OPTIONS = {
  iconStyle: 'solid',
  hidePostalCode: true,
  style: {
    base: {
      'iconColor': 'rgb(240, 57, 122)',
      'color': 'rgb(240, 57, 122)',
      'fontSize': '16px',
      'fontFamily': '"Open Sans", sans-serif',
      'fontSmoothing': 'antialiased',
      '::placeholder': {
        color: '#CFD7DF',
      },
    },
    invalid: {
      'color': '#e5424d',
      ':focus': {
        color: '#303238',
      },
    },
  },
};

export const CheckoutForm = ({ onSubmit }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { cartItems, getItemVariation } = useCart();
  const { enableFreeJointShipping } = useCurrentSite();
  const [cardDetailsFilled, setCardDetailsFilled] = useState(false);

  useEffect(() => {
    if (elements) {
      const cardElement = elements.getElement(CardElement);
      if (cardElement) {
        cardElement.on('change', (e) => setCardDetailsFilled(e.complete));
      }
    }
  }, [elements]);

  const onSubmitHandle = async (values, { setSubmitting }) => {
    if (!cardDetailsFilled) {
      return;
    }
    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      console.log(1111, result.error.message);
    }
    setSubmitting(true);
    await onSubmit({
      ...values,
      stripePaymentKey: result.token?.id,
      subTotal,
      shipping,
      total,
    });
    setSubmitting(false);
  };

  const { data: locationsData } = useQuery(GET_SHIPPING_LOCATIONS);
  const locations = locationsData?.shippingLocations?.edges ?? [];

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      addressLine1: '',
      addressLine2: '',
      zipPostalCode: '',
      shippingLocation: '',
      city: '',
      country: 'United States',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Please fill in the missing info above'),
      lastName: Yup.string().required('Please fill in the missing info above'),
      email: Yup.string().email('Invalid email').required('Please fill in the missing info above'),
      addressLine1: Yup.string().required('Please fill in the missing info above'),
      zipPostalCode: Yup.string()
        .required('Please fill in the missing info above')
        .when('shippingLocation', {
          is: (value) => {
            return !!locations.find((i) => i.node?.pk.toString() === value && i.node?.parent?.name === 'United States');
          },
          then: Yup.string()
            .trim()
            .matches(/^[0-9]{5}$/i),
        }),
      city: Yup.string().required('Please fill in the missing info above'),
      shippingLocation: Yup.string().required('Please fill in the missing info above'),
    }),
    onSubmit: onSubmitHandle,
  });

  const subTotal = cartItems
    .map((i) => Number(getItemVariation(i)?.getOnlinePrice || 0) * i.qty)
    .reduce((i, j) => (i += j), 0);

  const { data: shippingAmountData } = useQuery(GET_SHIPPING_AMOUNT, {
    variables: {
      country: formik.values.country || 'United States',
      variations: cartItems.map((i) => getItemVariation(i)?.pk),
    },
  });
  const shipping = Number(shippingAmountData?.shipping?.price ?? -1);
  const total = subTotal + (formik.values.enableFreeJointShipping ? 0 : shipping);
  return (
    <>
      <style jsx>{`
        .submitted .error {
          display: block;
        }
        .error {
          display: none;
          color: #d85b5e;
          font-weight: 500;
          font-size: 12px;
          padding: 0.275rem 1rem;
        }
        .error-2 {
          color: #d85b5e;
          font-weight: 500;
          font-size: 12px;
          padding: 0.275rem 1rem;
        }
        .joint-shipping-help-text {
          text-transform: none;
          font-size: 13px;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          letter-spacing: normal;
          color: #9ba3aa;
        }
      `}</style>
      <form onSubmit={formik.handleSubmit} className={classNames({ submitted: formik.submitCount > 0 })}>
        <div className="checkout__form">
          <div className="checkout__form__section">
            <label className="textMargin displayBlock">Contact Info</label>
            <div className="checkout__form__inputRow">
              <label className="input-label">
                <span>First Name</span>
                <input
                  type="text"
                  className="input input-with-label"
                  name="firstName"
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                />
              </label>
              &nbsp; &nbsp;
              <label className="input-label">
                <span>Last Name</span>
                <input
                  type="text"
                  className="input input-with-label"
                  name="lastName"
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                />
              </label>
            </div>
            {(formik.errors.firstName || formik.errors.lastName) && (
              <div className="error">Please fill in the missing info above</div>
            )}
            <div className="checkout__form__inputRow">
              <label className="input-label">
                <span>Email</span>
                <input
                  type="email"
                  className="input input-with-label"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                />
              </label>
            </div>
            {(formik.submitCount > 0 || formik.touched.email) && formik.errors.email && (
              <div className="error-2">{formik.errors.email}</div>
            )}
          </div>
          <div className="checkout__form__section">
            <label className="textMargin displayBlock">Shipping Address</label>
            <div className="checkout__form__inputRow">
              <CountrySelect name="country" onChange={formik.handleChange} value={formik.values.country} />
            </div>
            <div className="checkout__form__inputRow">
              <label className="input-label">
                <span>Address Line 1</span>
                <input
                  type="text"
                  className="input input-with-label"
                  name="addressLine1"
                  onChange={formik.handleChange}
                  value={formik.values.addressLine1}
                />
              </label>
            </div>
            <div className="error">{formik.errors.addressLine1}</div>
            <div className="checkout__form__inputRow">
              <label className="input-label">
                <span>Address Line 2 (opt)</span>
                <input
                  type="text"
                  className="input input-with-label"
                  name="addressLine2"
                  onChange={formik.handleChange}
                  value={formik.values.addressLine2}
                />
              </label>
            </div>
            <div className="checkout__form__inputRow">
              <label className="input-label">
                <span>Zip Code</span>
                <input
                  type="text"
                  className="input input-with-label"
                  name="zipPostalCode"
                  onChange={formik.handleChange}
                  value={formik.values.zipPostalCode}
                />
              </label>
              &nbsp; &nbsp;
              <label className="input-label">
                <span>&nbsp;</span>
                <LocationSelect
                  selectedCountry={formik.values.country}
                  name="shippingLocation"
                  onChange={formik.handleChange}
                  value={formik.values.shippingLocation}
                />
              </label>
            </div>
            {(formik.errors.zipPostalCode || formik.errors.shippingLocation) && (
              <div className="error">Please fill in valid info above</div>
            )}
            <div className="checkout__form__inputRow">
              <label className="input-label">
                <span>City</span>
                <input
                  type="text"
                  className="input input-with-label"
                  name="city"
                  onChange={formik.handleChange}
                  value={formik.values.city}
                />
              </label>
            </div>
            <div className="error">{formik.errors.city}</div>
          </div>
          {enableFreeJointShipping && (
            <div className="checkout__form__section">
              <label className="textMargin displayBlock">Shipping Options</label>
              <div className="checkout__form__inputRow">
                <label>
                  <input
                    type="checkbox"
                    name="enableFreeJointShipping"
                    onChange={formik.handleChange}
                    value={formik.values.enableFreeJointShipping}
                  />
                  <span style={{ position: 'relative', top: -2.5 }}>&nbsp; Enable free joint-shipping</span>
                  <div className="joint-shipping-help-text">
                    <br />
                    Selecting this option will allow you to ship for free directly to the fundraising organization.
                    (Items will be held back until the fundraiser closes and then shipped in bulk.)
                  </div>
                </label>
              </div>
              <div className="error">{!cardDetailsFilled && 'Enter valid credit card deails'}</div>
            </div>
          )}
          <div className="checkout__form__section">
            <label className="textMargin displayBlock">Credit Card Information</label>
            <div className="checkout__form__inputRow">
              <div className="input">
                <CardElement options={CARD_ELEMENT_OPTIONS} />
              </div>
            </div>
            <div className="error">{!cardDetailsFilled && 'Enter valid credit card deails'}</div>
          </div>
        </div>
        <div className="checkout__right">
          <p className="textMargin">
            Subtotal:
            <span style={{ float: 'right' }}>${subTotal.toFixed(2)}</span>
          </p>
          {(shipping === -1 && !formik.values.enableFreeJointShipping && (
            <p className="textMargin">Select location to view shipping & total amount</p>
          )) || (
            <>
              <p className="textMargin">
                Shipping:
                <span style={{ float: 'right' }}>
                  {(formik.values.enableFreeJointShipping && <>Free</>) || <>${shipping.toFixed(2)}</>}
                </span>
              </p>
              <p
                className="textMargin"
                style={{
                  borderTop: '1px solid #dce0e3',
                  paddingTop: '.5em',
                  color: '#596066',
                }}
              >
                Total:
                <span style={{ float: 'right' }}>${total.toFixed(2)}</span>
              </p>
            </>
          )}

          <div style={{ textAlign: 'center' }}>
            <p className="mutedText">
              You will receive a confirmation email with your order information when payment is complete.
            </p>
          </div>
        </div>
        <div className="checkout__purchase">
          {formik.isSubmitting && <CircularProgress size={30} />}
          {!formik.isSubmitting && (
            <button type="submit" className="button">
              Make Purchase
            </button>
          )}
          {(formik.submitCount && (!formik.isValid || !cardDetailsFilled) && (
            <div style={{ textAlign: 'center', margin: '1rem 0 2rem' }}>
              <p className="error">Please fix the errors above to continue.</p>
            </div>
          )) ||
            null}
          <div className="stripeImg">
            <Image src={stripeImg} alt="Powered By Stripe" />
          </div>
        </div>
      </form>
    </>
  );
};

export const GET_SHIPPING_AMOUNT = gql`
  query GET_SHIPPING_AMOUNT($country: String!, $variations: [Int]!) {
    shipping: shippingCountryPrice(country: $country, variations: $variations) {
      price
    }
  }
`;
