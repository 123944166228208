export const isServer = () => typeof window === 'undefined';

export const getEnv = () => {
  if (isServer()) {
    return process.env.APP_ENV || 'LOCAL_DEV';
  }
  // in _app.js this will be set to window object
  return window.__APP_ENV || 'LOCAL_DEV';
};

export const isLocalDev = () => getEnv() === 'LOCAL_DEV' || getEnv() === 'development';

export const isProd = () => getEnv() === 'PROD';
export const isProdOrStage = () => getEnv() === 'PROD' || getEnv() === 'STAGE';

export const GRAPHQL_URL = (req) => {
  if (isServer()) {
    return `${(isLocalDev() && 'http') || 'https'}://${req.headers.host}/graphql`;
  }
  return `${window.location.origin}/graphql`;
};
