import { Snackbar, Slide, Alert } from '@mui/material';
import { createContext, useState, useContext } from 'react';

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

export const AlertContext = createContext();

export const useAlerts = () => {
  return useContext(AlertContext);
};

export const AlertContextEl = ({ children }) => {
  const [alerts, setAlerts] = useState([]);

  function successAlert(message = 'Success') {
    setAlerts([...alerts, { open: true, id: Math.random(), message, severity: 'success' }]);
  }

  function errorAlert(message = 'Error') {
    setAlerts([...alerts, { open: true, id: Math.random(), message, severity: 'error' }]);
  }

  function warningAlert(message = 'Warning') {
    setAlerts([...alerts, { open: true, id: Math.random(), message, severity: 'warning' }]);
  }

  function handleClose(alert) {
    alert.open = false;
    setAlerts([...alerts]);
    setTimeout(() => {
      alerts.splice(alerts.indexOf(alert), 1);
      setAlerts([...alerts]);
    }, 5000);
  }

  return (
    <AlertContext.Provider value={{ successAlert, errorAlert, warningAlert }}>
      {children}
      {alerts.map((alert) => (
        <Snackbar
          key={alert.id}
          open={alert.open}
          autoHideDuration={4000}
          TransitionComponent={SlideTransition}
          onClose={() => handleClose(alert)}
        >
          <Alert variant="filled" onClose={() => handleClose(alert)} severity={alert.severity}>
            {alert.message}
          </Alert>
        </Snackbar>
      ))}
    </AlertContext.Provider>
  );
};
