import { useState } from 'react';
import { AccordionItem } from './components/AccordionItem';

export const Accordion = (props) => {
  const [clicked, setClicked] = useState('0');

  const handleToggle = (index) => {
    if (clicked === index) {
      return setClicked('0');
    }
    setClicked(index);
  };

  return (
    <div className="accordion">
      {props.accordionData.map((item, index) => {
        return <AccordionItem key={index} faq={item} onToggle={() => handleToggle(index)} active={clicked === index} />;
      })}
    </div>
  );
};
