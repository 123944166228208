import { useState } from 'react';
import styles from './SearchBoxCategoryMenu.module.css';
import { ProductNavigator } from '../../components/productNavigator';

export const SearchBoxCategoryMenu = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleClick = (e) => {
    setShowMenu(false);
  };

  return (
    <>
      <button className="iconButton hideOnDesktop" onClick={() => setShowMenu(true)}>
        <i className="fas fa-bars fa-fw"></i>
      </button>
      <div className={`${styles.navcatContainer} ${!showMenu ? styles.hideMenu : ''}`} onClick={handleClick}>
        <div className={`${styles.navcatContainerInner} productNavigatorMobile`}>
          <ProductNavigator />
        </div>
      </div>
    </>
  );
};
