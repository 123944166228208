import React from 'react';

export const ExtraFooter = () => {
  return (
    <div className="extraFooter">
      <h3>About EveryName.com</h3>
      <p>
        We have a passion for capturing life’s best memories. Since 1999, we’ve produced souvenirs to celebrate favorite
        vacations and adventures – those rare moments when life stops and allows us all to laugh a little more and
        rekindle our relationships with each other.
        <br />
        <br />
        As a husband and wife team, family is important to us, both our own as well as our incredible work family (and
        occasionally with teenagers at home, our work family may seem preferable to our own). We like focusing on the
        relationships that matter most in the products we create. Almost everything we do is made in the USA and custom
        built at our office in Orem, Utah. Working with domestically-sourced raw materials allows us to personalize any
        product with any name and ship in 1-3 business days. Each custom order is carefully reviewed for quality
        control.
        <br />
        <br />
        For 21 years, our products have been found exclusively in souvenir stores across the country. Now with
        EveryName.com, we’ve opened our unique customization capabilities to the online world - items to remind you of
        places you’ve been or even dream destinations still on your bucket list. We’ve also added new household and gift
        options like personalized home décor, cute pet products, and memorable gifts to commemorate life’s milestones.
        Please browse the site. We hope you’ll find something just right for yourself and those you love!
        <br />
        <br />- Nate and Bonnie Gunderson
      </p>
    </div>
  );
};
