import { useEffect } from 'react';
import '../src/styles.scss';
import Head from 'next/head';
import App from 'next/app';
import Router, { useRouter } from 'next/router';
import theme from '../src/theme';
import { Alert2ContextEl } from '../src/common/context/Alert2Context';
import { CartContextEl } from '../src/common/context/CartContext';
import { AlertContextEl } from '../src/common/context/AlertContext';
import { CurrentSiteState } from '../src/common/context/CurrentSiteState';
import withApollo from 'next-with-apollo';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import NProgress from 'nprogress';
import Cookies from 'universal-cookie';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Footer, Nav } from '../src/common/components';
import { GRAPHQL_URL, isProd, getEnv } from '../src/environment';
import { StoreWelcomeAlert } from '../src/common/modals/storeWelcomeAlert';

const stripePromise = loadStripe(isProd() ? `pk_live_hB28dIRF2JoL76eukladhZND` : 'pk_test_xqtiWdHLMpGVn4WuPC8iLf4S');

function MainApp({ Component, pageProps, appProps, apollo }) {
  const router = useRouter();
  const cookies = new Cookies();

  useEffect(() => {
    if (cookies.get('accessCode') !== appProps.accessCode) {
      cookies.set('accessCode', appProps.accessCode, { path: '/' });
    }
  }, [appProps]);

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      NProgress.start();
    });
    router.events.on('routeChangeComplete', (url) => {
      NProgress.done();
    });
    router.events.on('routeChangeError', () => NProgress.done());
    return () => {
      router.events.off('routeChangeComplete', (url) => {
        NProgress.done();
      });
    };
  }, [router.events]);

  return (
    <ApolloProvider client={apollo}>
      <ThemeProvider theme={theme}>
        <Elements stripe={stripePromise}>
          <AlertContextEl>
            <Alert2ContextEl>
              <CssBaseline />
              <Head>
                <title>EveryName.com - Customized Gifts & Souvenirs for Every Name</title>
                <script
                  dangerouslySetInnerHTML={{
                    __html: `window.__APP_ENV='${getEnv()}'`,
                  }}
                ></script>
                <link rel="icon" href="/favicon.ico" />
                <link
                  rel="stylesheet"
                  href="https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap"
                />
                <link rel="stylesheet" href="/assets/nprogress.css" type="text/css" />
                {isProd() && (
                  <>
                    {/* Google Analytics */}
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-LV5NCPJKHN"></script>
                    <script
                      dangerouslySetInnerHTML={{
                        __html: `
                          window.dataLayer = window.dataLayer || [];
                          function gtag(){dataLayer.push(arguments);}
                          gtag('js', new Date());

                          gtag('config', 'G-LV5NCPJKHN');`,
                      }}
                    ></script>
                    {/* Meta Pixel */}
                    <script
                      dangerouslySetInnerHTML={{
                        __html: `
                        !function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq('init', '290786866929045');
                        fbq('track', 'PageView');
                      `,
                      }}
                    ></script>
                    <noscript>
                      <img
                        height="1"
                        width="1"
                        style={{ display: 'none' }}
                        src="https://www.facebook.com/tr?id=290786866929045&ev=PageView&noscript=1"
                      />
                    </noscript>
                  </>
                )}
              </Head>
              <CurrentSiteState initialProps={{ ...appProps }}>
                <CartContextEl>
                  <Nav />
                  <div className="routesContainer">
                    <div className="pageContainer">
                      <Component {...pageProps} />
                    </div>
                  </div>
                  <Footer />
                  <StoreWelcomeAlert />
                </CartContextEl>
              </CurrentSiteState>
            </Alert2ContextEl>
          </AlertContextEl>
        </Elements>
      </ThemeProvider>
    </ApolloProvider>
  );
}

MainApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  const cookies = new Cookies(appContext.ctx?.req?.headers?.cookie);
  const [pathname] = appContext.ctx.asPath.split('?');
  const unAuthedPaths = ['/login', '/faq', '/contact', '/fundraisers'];

  if (appContext.ctx.query.accessCode) {
    cookies.set('accessCode', appContext.ctx.query.accessCode, { path: '/', httpOnly: true });
  }

  if (!cookies.get('accessCode') && !unAuthedPaths.includes(pathname)) {
    if (appContext.ctx.req) {
      appContext.ctx.res.setHeader('Location', '/login');
      appContext.ctx.res.status(302);
      appContext.ctx.res.end();
    } else {
      Router.push('login');
    }
  }

  return { ...appProps, appProps: { accessCode: cookies.get('accessCode') } };
};

MainApp = withApollo(({ initialState, ...props }) => {
  const headers = {};
  if (props.ctx && props.ctx.req) {
    headers.cookie = props.ctx.req.headers.cookie;
  }
  return new ApolloClient({
    uri: GRAPHQL_URL(props.ctx && props.ctx.req),
    cache: new InMemoryCache().restore(initialState || {}),
    credentials: 'include',
    headers,
  });
})(MainApp);

export default MainApp;
