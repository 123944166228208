import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_NAV_MENU_ITEMS } from '../components/productNavigator';

export const useGetMenuItems = (accessCode) => {
  const [loadNavMenuItems, { data, loading }] = useLazyQuery(GET_NAV_MENU_ITEMS, { variables: { code: accessCode } });

  useEffect(() => {
    loadNavMenuItems();
  }, [accessCode]);

  return { data, loading };
};
