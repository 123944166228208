import Router from 'next/router';
import { ProductGrid } from '../productGrid';

export const FeaturedProducts = ({ products = [] }) => {
  // const prefetch = (id) => () => Router.prefetch(`/product/[id]`, `/product/${id}`);

  return (
    <>
      <h1 className="textMargin alignCenter">Featured Products</h1>
      <ProductGrid products={products} />
    </>
  );
};
