import { useState, useEffect } from 'react';
import { useAlerts } from '../../context/Alert2Context';
import { useCart } from '../../context/CartContext';
import { formatCurrrency } from '../currency/Currency';
import { RenderImage } from '../renderImage/RenderImage';

export const CartItem = ({ item = null }) => {
  const [qty, setQty] = useState(item?.qty ?? 1);
  const { successAlert } = useAlerts();
  const { getItemVariation, addItem, updateItem, removeItem } = useCart();
  const variation = getItemVariation(item);
  useEffect(() => {
    setQty(item.qty);
  }, [item]);

  function hasBackImage() {
    if (!item.product.shape || !item.product.shape.productViewList) {
      return false;
    }
    return item.product.shape.productViewList.includes('product_back');
  }

  function renderImages() {
    return (
      <>
        <div className="cartItem__image">
          {hasBackImage() && <div className="cartItem__imageLabel">Front</div>}
          <RenderImage productId={item.product.pk} width={192} data={item.data} variation={item.variation} />
        </div>
        {hasBackImage() && (
          <div className="cartItem__image">
            <div className="cartItem__imageLabel">Back</div>
            <RenderImage
              productId={item.product.pk}
              view={'product_back'}
              width={192}
              data={item.data}
              variation={item.variation}
            />
          </div>
        )}
      </>
    );
  }

  function changeItemQuantity(e) {
    setQty(e.target.value);
  }

  function updateItemQty() {
    let newQty = (qty > 0 && qty) || 1;
    updateItem(item.uuid, { ...item, qty: newQty });
  }

  function clickRemoveItem(e) {
    removeItem(item.uuid);
    successAlert(
      item.product.name + ' has been removed from your cart.',
      () => {
        addItem({
          product: item.product,
          data: item.data,
          qty,
        });
      },
      'Undo'
    );
  }

  if (!item) {
    return null;
  }

  return (
    <div className="cartItem">
      <p>
        {item.product.description} - {variation?.longName} ({formatCurrrency(variation?.getOnlinePrice)} ea.)
      </p>
      <div className="cartItem__flex">
        <div className="cartItem__images">{renderImages()}</div>
        <div className="cartItem__actions">
          <div className="cartItem__quantity">
            <input
              className="input"
              type="number"
              min="1"
              value={qty}
              onChange={(e) => changeItemQuantity(e)}
              onBlur={updateItemQty}
            />
            <button className="iconButton--greenBg" onClick={(e) => clickRemoveItem(e)}>
              <i className="fas fa-times fa-fw"></i>
            </button>
          </div>
          <strong>{formatCurrrency(variation?.getOnlinePrice * qty)}</strong>
        </div>
      </div>
    </div>
  );
};
