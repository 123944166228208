import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import gql from 'graphql-tag';
import queryString from 'query-string';

export const CustomFilter = () => {
  const router = useRouter();
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const query = queryString.parseUrl(router.asPath).query;
    if (query.custom === 'only') {
      setIsChecked(true);
    }
  }, [])

  useEffect(() => {
    const prevQuery = queryString.parseUrl(router.asPath).query;
    if (!isChecked) {
      let url = queryString.stringifyUrl({
        url: router.asPath.split('?')[0],
        query: { ...prevQuery, custom: undefined },
      });
      router.replace(router.pathname, url);
    }
    if (isChecked) {
      let url = queryString.stringifyUrl({
        url: router.asPath.split('?')[0],
        query: { ...prevQuery, custom: 'only' },
      });
      router.replace(router.pathname, url);
    }
  }, [isChecked]);

  return (
    <label className="customFilterContainer">
      Only Show Customizable Products
      <input
        className="storeFilters__toggle"
        type="checkbox"
        name="productsToShow"
        value="showCustomizable"
        checked={isChecked}
        onChange={() => setIsChecked((prevChecked) => !prevChecked)}
      />
      <span className="customFilterCheckmark" />
    </label>
  );
};

export const ALL_GROUPS = gql`
  query ALL_GROUPS {
    groups: storeGroupFilters {
      edges {
        node {
          id
          pk
          name
        }
      }
    }
  }
`;
