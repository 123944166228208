export const renderImageUrl = (productId, view, height, width, data, variation) => {
  let baseUrl = `/ops-api/images/${productId}/render/`;
  let viewQuery = `?view=${view ?? 'product'}`;
  let heightQuery = `${height ? `&height=${height}` : ''}`;
  let widthQuery = `${width ? `&width=${width}` : ''}`;
  let variationQuery = `${variation ? `&variation=${variation}` : ''}`;
  let dataQuery = `${
    data
      ? `&data=${typeof data === 'string' ? encodeURIComponent(data) : encodeURIComponent(JSON.stringify(data))}`
      : ''
  }`;
  return `${baseUrl}${viewQuery}${heightQuery}${widthQuery}${dataQuery}${variationQuery}`;
};

export const RenderImage = ({ productId, view, height, width, data, variation }) => {
  const base = renderImageUrl(productId, view, height, width, data, variation);
  return <img src={base} alt="" />;
};
