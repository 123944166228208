import Link from 'next/link';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import { ProductGrid } from '../productGrid';

export const SearchResults = (props) => {
  const router = useRouter();
  const queryParams = queryString.parseUrl(router.asPath).query;
  delete queryParams.filters;
  delete queryParams.custom;

  let searchUrlWithNoFiltersUrl = queryString.stringifyUrl({
    url: '/',
    query: queryParams,
  });

  return props.products.length === 0 ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        minHeight: '20em',
      }}
    >
      <div
        style={{
          textAlign: 'center',
        }}
      >
        No Results Found. Some results may have been filtered <br />
        <br />
        <Link passHref href={searchUrlWithNoFiltersUrl}>
          <a>Show all products</a>
        </Link>
      </div>
    </div>
  ) : (
    <ProductGrid products={props.products} />
  );
};
