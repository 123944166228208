import { Helmet } from 'react-helmet';

export const PageTitle = ({ children = '' }) => {
  return (
    <Helmet>
      <title>
        {children} {children && '|'} EveryName.com - Customized Gifts & Souvenirs for Every Name
      </title>
    </Helmet>
  );
};
