import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';
import queryString from 'query-string';
import styles from '../../../scss/components/SearchBox.module.scss';
import { SearchBoxCategoryMenu } from '../../components/searchBoxCategoryMenu';
import { useDebounce } from '../../hooks/useDebounce';

export const SearchBox = () => {
  const router = useRouter();
  const [loadTags, { data: tagsData, loading }] = useLazyQuery(TAG_SEARCH);
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [focused, setFocused] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 160);

  useEffect(() => {
    const query = queryString.parseUrl(router.asPath).query;
    if (query.q) setSearchText(query.q);
  }, [router]);

  useEffect(() => {
    setOptions(tagsData?.tags.edges);
  }, [tagsData?.tags.edges?.map(({ node }) => node.id).join()]);

  useEffect(() => {
    if (debouncedSearchText?.length >= 4) {
      let accessCode = router.query.accessCode;
      loadTags({
        variables: {
          query: debouncedSearchText,
          code: accessCode,
        },
      });
    }
  }, [debouncedSearchText]);

  function doSearch(searchText) {
    let query = {
      ...queryString.parseUrl(router.asPath).query,
      q: searchText,
      before: undefined,
      after: undefined,
    };
    if (!query.q) {
      delete query.q;
    }
    let url = queryString.stringifyUrl({
      url: router.asPath.split('?')[0],
      query,
    });

    router.push(router.pathname, url);
  }

  return (
    <div className={`${styles.searchBox}`}>
      <div className="searchContainer">
        <SearchBoxCategoryMenu />
        <input
          id="seachBoxInput"
          type="text"
          placeholder="Search products..."
          className={`input ${styles.searchInput}`}
          value={searchText}
          onChange={(e) => {
            setOptions([]);
            setSearchText(e.currentTarget.value);
          }}
          onFocus={() => setFocused(true)}
          onBlur={() =>
            setTimeout(() => {
              setFocused(false);
            }, 250)
          }
          onKeyPress={(e) => {
            setFocused(true);
            if (e.key === 'Enter') {
              doSearch(searchText);
              setFocused(false);
            }
          }}
        />
        {searchText && (
          <button
            className="textButton clear"
            onClick={() => {
              setSearchText('');
              doSearch();
            }}
          >
            <i className="fas fa-times-circle fa-fw"></i>
          </button>
        )}
        <button className="iconButton--greenBg" onClick={() => doSearch(searchText)}>
          <i className="fas fa-search fa-fw"></i>
        </button>
      </div>
      {focused && !loading && searchText?.length >= 4 && options?.length > 0 && (
        <ul className={`${styles.searchListBox}`}>
          {options.map(({ node }, index) => (
            <li
              key={node.id}
              onClick={() => {
                setSearchText(node.name);
                doSearch(node.name);
              }}
            >
              {node.name}
            </li>
          ))}
        </ul>
      )}
      {loading && focused && (
        <ul className={`${styles.searchBox}`}>
          <li className={`${styles.li}`}>Loading...</li>
        </ul>
      )}
    </div>
  );
};

export const TAG_SEARCH = gql`
  query TAG_SEARCH($query: String!, $code: String!) {
    tags(name: $query, code: $code) {
      edges {
        node {
          id
          name
          pk
        }
      }
    }
  }
`;
