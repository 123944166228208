import React, { useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Dialog, DialogContent, Slide, Grid } from '@mui/material';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';
import { useCurrentSite } from '../../context/CurrentSiteState';
import heroShapeImg from '../../../assets/img/hero-shape.png';
import banner from '../../../assets/img/welcome-modal/banner.jpg';
import imgOne from '../../../assets/img/welcome-modal/01.jpg';
import imgTwo from '../../../assets/img/welcome-modal/02.jpg';
import imgThree from '../../../assets/img/welcome-modal/03.jpg';
import imgFour from '../../../assets/img/welcome-modal/04.jpg';

const imgSet = [imgOne, imgTwo, imgThree, imgFour];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const GET_STORE_FEATURED_PRODUCTS = gql`
  query GET_STORE_FEATURED_PRODUCTS($code: String!) {
    featuredProducts: storeFeaturedProducts(code: $code) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

export const StoreWelcomeAlert = () => {
  const [showModal, setShowModal] = useState(false);

  const { accessCode } = useCurrentSite();
  const router = useRouter();

  const [getFeaturedProducts, { data }] = useLazyQuery(GET_STORE_FEATURED_PRODUCTS);

  React.useEffect(() => {
    if (!accessCode) return;
    console.log(accessCode);
    getFeaturedProducts({ variables: { code: accessCode } });
  }, [accessCode]);

  function closeModal() {
    setShowModal(false);
    localStorage.setItem('seenWelcomeMessage', true);
  }

  return (
    <>
      <style jsx>{`
        .welcome {
          position: relative;
          margin: auto -24px;
        }
        .welcome .wave {
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
        }
        .welcome h1 {
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
        }
        .paragraph {
          font-style: italic;
        }
        .featured-img {
          max-height: 100px;
        }
      `}</style>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeModal}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <div className="storeWelcome">
            <div className="welcome">
              <Image className="wave" src={heroShapeImg} alt="" />
              <h1>
                Welcome <span className="offset">to</span> EveryName
                <span className="offset">.com</span>
              </h1>
              <Image className="image" src={banner} alt="" />
            </div>
            <div className="paragraph">
              We offer a large variety of quality gifts and souvenirs.
              <br />
              We produce and ship your customized product fast!
            </div>
            <br />
            <Grid container justify="space-around" spacing={2}>
              {imgSet.map?.((img, index) =>
                index < 4 ? (
                  <Grid key={index} item xs={6} style={index % 2 ? { textAlign: 'left' } : { textAlign: 'right' }}>
                    <Image className="featured-img" src={img} alt="" />
                  </Grid>
                ) : null
              )}
            </Grid>
            <br />
            <br />
            <button className="button getStarted" onClick={() => closeModal}>
              Get Started
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
