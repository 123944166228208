import { useState } from 'react';
import Router from 'next/router';

export const SubCategoriesList = ({ categories = [] }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <>
      <style jsx>{`
        .cat-more {
          position: absolute;
          right: 0;
          top: 0;
          color: #92c954;
          cursor: pointer;
        }
        .cat-children {
          position: absolute;
          top: calc(100% + 0.6em);
          right: 0;
          width: 200px;
          z-index: 1;
        }
        .listbox {
          width: 100%;
          margin: 0;
          padding: 0;
          zindex: 1;
          position: absolute;
          list-style: none;
          border-radius: 4px;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
          overflow: hidden;
          background: #fff;
          max-height: 35vh;
          overflow-y: auto;
        }
        .listbox li:hover {
          background-color: rgba(60, 208, 222, 0.25);
          cursor: pointer;
        }
        .listbox li {
          background-color: #fff;
          color: #596066;
          font-size: 16px;
          padding: 13px 8px;
          text-align: left;
        }
      `}</style>
      <div className="cat-more " onClick={() => setShowDropdown(!showDropdown)}>
        More{' '}
        {(showDropdown && <i className="fas fa-angle-up fa-fw"></i>) || <i className="fas fa-angle-down fa-fw"></i>}
        {showDropdown && (
          <div className="cat-children">
            <ul className="listbox">
              {categories.map((cat, index) => (
                <li key={cat.id} onClick={() => Router.push(`/category/[id]`, `/category/${cat.pk}`)}>
                  {cat.name}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};
