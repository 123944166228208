import React, { useState, useEffect } from 'react';
import logo from '../../../assets/img/logo.png';
import { useCurrentSite } from '../../context/CurrentSiteState';
import { useCart } from '../../context/CartContext';
import Router from 'next/router';
import Image from 'next/image';
import Link from 'next/link';

export const Nav = () => {
  const { accessCode } = useCurrentSite();
  const { cartItems } = useCart();
  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    setCartCount(cartItems.length);
  }, [cartItems]);

  return (
    <div className="nav">
      <div className="ssContainer">
        <div className="nav__content">
          <div className="nav__left">
            <Image
              className="nav__logo pointer"
              src={logo}
              onClick={() => {
                window.scrollTo(0, 0);
                Router.push('/', '/');
              }}
              alt="Everyname"
            />
          </div>
          <div className="nav__right">
            {accessCode && (
              <>
                <Link href={`/${accessCode}`} passHref>
                  <a className="navLink link--white">
                    <span className="hideOnMobile">Home</span>
                  </a>
                </Link>

                <Link href={`/${accessCode}/cart`} passHref>
                  <a className="navLink link--white relativeContainer">
                    <i className="fa fa-shopping-cart fa-fw" />
                    {cartCount > 0 && <span className="cart-count">{cartCount}</span>}
                    <span className="hideOnMobile">Cart</span>
                  </a>
                </Link>
              </>
            )}
            <Link href="/faq" passHref>
              <a className="navLink link--white">
                <i className="fa fa-question-circle fa-fw" />
                <span className="hideOnMobile">Faq</span>
              </a>
            </Link>
            <Link href="/contact" passHref>
              <a className="navLink link--white">
                <i className="fa fa-envelope fa-fw" />
                <span className="hideOnMobile">Contact</span>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
