import React, { useState, forwardRef } from 'react';
import { useRouter } from 'next/router';
import { Dialog, Slide } from '@mui/material';
import { StoreSelectForm } from '../storeSelectForm';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AccessCodeModel = (props) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('online');
  const [currentStep, setCurrentStep] = useState(1);

  return (
    <>
      <button
        className="textButton"
        style={{ fontSize: '14px', textDecoration: 'underline' }}
        type="button"
        onClick={() => setIsOpen(true)}
      >
        {props.triggerText ? props.triggerText : 'No Access Code?'}
      </button>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        onClose={() => setIsOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xs"
        fullWidth={true}
      >
        <div className="accessCode">
          <h3>Access Code</h3>
          <div className="coloredDivider"></div>
          {currentStep === 1 && (
            <>
              <p className="textMargin">How did you find our website?</p>
              <label className="radioContainer">
                Online
                <input
                  type="radio"
                  name="radio"
                  value="online"
                  checked={selectedOption === 'online'}
                  onChange={(e) => setSelectedOption(e.currentTarget.value)}
                />
                <span className="checkmark"></span>
              </label>
              <label className="radioContainer">
                From a store display
                <input
                  type="radio"
                  name="radio"
                  value="store"
                  checked={selectedOption === 'store'}
                  onChange={(e) => setSelectedOption(e.currentTarget.value)}
                />
                <span className="checkmark"></span>
              </label>
              <div className="buttons">
                <button
                  className="textButton"
                  onClick={() => {
                    if (selectedOption === 'online') {
                      localStorage.setItem('seenWelcomeMessage', true);
                      window.location = '/en99';
                      return;
                    }
                    setCurrentStep(2);
                  }}
                >
                  Next <i className="fas fa-angle-right fa-fw"></i>
                </button>
              </div>
            </>
          )}
          {currentStep === 2 && (
            <StoreSelectForm gotoPrevStep={() => setCurrentStep(1)} closeModal={() => setIsOpen(true)} />
          )}
        </div>
      </Dialog>
    </>
  );
};
