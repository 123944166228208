import { useState } from 'react';
import Link from 'next/link';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useAlerts } from '../../context/AlertContext';

export const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    accessCode: '',
    orderNumber: '',
    subject: '',
    message: '',
  });
  const { errorAlert } = useAlerts();

  const [sendContactEmail, { data }] = useMutation(SEND_CONTACT_EMAIL, {
    onCompleted: (data) => {
      clearInputs();
    },
    onError: (error) => {
      errorAlert('Error sending contact email.');
    },
  });

  function handleInput(e) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  function clearInputs() {
    setFormData({
      name: '',
      email: '',
      accessCode: '',
      orderNumber: '',
      subject: '',
      message: '',
    });
  }

  function submitDisabled() {
    return !formData.email || !formData.subject || !formData.name || !formData.message;
  }

  function handleSubmit(e) {
    e.preventDefault();
    sendContactEmail({
      variables: {
        fromEmail: formData.email,
        subject: formData.subject,
        name: formData.name,
        message: formData.message,
        accessCode: formData.accessCode ? formData.accessCode : null,
        orderNumber: formData.orderNumber ? formData.orderNumber : null,
      },
    });
  }

  if (data) {
    return (
      <div className="contactForm">
        <p>Your inquiry has been sent to Customer Service. We will reach out to you soon!</p>
      </div>
    );
  }

  return (
    <div className="contactForm">
      <form onSubmit={handleSubmit}>
        <p className="errorText">
          <i className="fas fa-exclamation-triangle fa-fw"></i> Before you ask, check our{' '}
          <Link href="/faq" passHref>
            <a className="link">FAQ page</a>
          </Link>{' '}
          first!
        </p>
        <input
          className="input"
          type="text"
          placeholder="Your Name"
          name="name"
          value={formData.name}
          onChange={handleInput}
        ></input>
        <input
          className="input"
          type="email"
          placeholder="Your Email"
          name="email"
          value={formData.email}
          onChange={handleInput}
        ></input>
        <input
          className="input"
          type="text"
          placeholder="Access Code (if applicable)"
          name="accessCode"
          value={formData.accessCode}
          onChange={handleInput}
        ></input>
        <input
          className="input"
          type="text"
          placeholder="Order Number (if applicable)"
          name="orderNumber"
          value={formData.orderNumber}
          onChange={handleInput}
        ></input>
        <input
          className="input"
          type="text"
          placeholder="Subject"
          name="subject"
          value={formData.subject}
          onChange={handleInput}
        ></input>
        <textarea className="input" name="message" value={formData.message} onChange={handleInput}></textarea>
        <input className="hollowButton" type="submit" disabled={submitDisabled()} value="Send Message" />
      </form>
    </div>
  );
};

export const SEND_CONTACT_EMAIL = gql`
  mutation contactEmail(
    $fromEmail: Email!
    $subject: String!
    $name: String!
    $message: String!
    $accessCode: String
    $orderNumber: Int
  ) {
    contactEmail(
      fromEmail: $fromEmail
      subject: $subject
      name: $name
      message: $message
      accessCode: $accessCode
      orderNumber: $orderNumber
    ) {
      emailSent
      errors
    }
  }
`;
