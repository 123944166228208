import { useEffect } from 'react';
import { useLazyQuery, gql } from '@apollo/client';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import Link from 'next/link';
import { useCurrentSite } from '../../context/CurrentSiteState';
import { SearchResults } from '../searchResults';
import { PageTitle } from '../../utils/react-helmet-helpers';
import { Loader } from '../loader';

export const SearchProducts = ({ category }) => {
  const router = useRouter();
  const { accessCode } = useCurrentSite();
  const query = router ? queryString.parseUrl(router.asPath).query : {};
  const [
    doSearch,
    { called, loading, data: { products: { edges: productResults = [], pageInfo: productPageInfo } = {} } = {} },
  ] = useLazyQuery(SEARCH_PRODUCTS, {
    variables: {
      after: query.after,
      before: query.before,
    },
  });

  useEffect(() => {
    let variables = {
      accessCode,
      search: query.q,
      group: query.filters?.split('-'),
    };

    if (category) {
      variables.tag = category;
    }

    if (query.custom) {
      variables.customizable = query.custom === 'only';
    }
    doSearch({
      variables,
    });
  }, [router]);

  if ((!category && !query.q) || !productResults) {
    return null;
  }
  return (
    <>
      {query.q && (
        <div className="resultTitle">
          <PageTitle>{`"${query.q}" - search`}</PageTitle>
          <h1 className="textMargin alignCenter">Search Results for "{query.q}"</h1>
        </div>
      )}
      {productResults && !loading && called ? (
        <SearchResults products={productResults} hasFilters={!!category || !!query.q} />
      ) : (
        <Loader />
      )}
      {productResults && <Pagination pageInfo={productPageInfo} />}
    </>
  );
};
// TODO: Make this it's own component
function Pagination({ pageInfo }) {
  const router = useRouter();
  let query = queryString.parseUrl(router.asPath).query;

  const previousUrl = queryString.stringifyUrl({
    url: router.asPath,
    query: {
      ...query,
      before: pageInfo && pageInfo.startCursor,
      after: undefined,
    },
  });
  const nextUrl = queryString.stringifyUrl({
    url: router.asPath,
    query: {
      ...query,
      before: undefined,
      after: pageInfo && pageInfo.endCursor,
    },
  });

  return (
    <>
      <style jsx>
        {`
          .root {
            text-align: center;
          }
          a {
            text-decoration: none;
          }
          a:hover {
            text-decoration: underline;
          }
        `}
      </style>
      <div className="root">
        {pageInfo && pageInfo.hasPreviousPage && (
          <Link passHref href={previousUrl}>
            <a>
              <i className="fas fa-angle-left fa-fw"></i> Previous
            </a>
          </Link>
        )}
        &nbsp; &nbsp; &nbsp; &nbsp;
        {pageInfo && pageInfo.hasNextPage && (
          <Link passHref href={nextUrl}>
            <a>
              Next <i className="fas fa-angle-right fa-fw"></i>
            </a>
          </Link>
        )}
      </div>
    </>
  );
}

export const SEARCH_PRODUCTS = gql`
  query SEARCH_PRODUCTS(
    $accessCode: String
    $search: String
    $customizable: Boolean
    $group: [ID]
    $tag: ID
    $after: String
    $before: String
  ) {
    products(
      code: $accessCode
      search: $search
      isCustomizable: $customizable
      storeGroupFilter: $group
      tag: $tag
      after: $after
      before: $before
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          name
          description
          isVariable
          group {
            name
          }
          shape {
            productViewList
            defaultVariation {
              pk
              getOnlinePrice
            }
          }
        }
      }
    }
  }
`;
