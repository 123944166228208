import { useEffect, useState, useRef } from 'react';
import styles from './LoadingDots.module.css';

export const LoadingDots = () => {
  const [dots, setDots] = useState();
  const dotsRef = useRef('·');

  useEffect(() => {
    const interval = setInterval(() => {
      dotsRef.current = '·'.repeat(((dotsRef.current?.length ?? 0) + 1) % 4);
      setDots(dotsRef.current);
    }, 250);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return <span className={styles.dots}>{dots}</span>;
};

export const LoadingDotsSwitch = ({ loading, loadingText = 'loading', children }) => (
  <>
    {loading && loadingText}
    {loading && <LoadingDots />}
    {!loading && children}
  </>
);
