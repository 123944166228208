import React from 'react';

export const Footer = () => {
  return (
    <div className="outer-footer">
      <div className="ssContainer">
        <div className="footer">
          <div className="buttons">
            <a href="https://instagram.com/EveryNamecom/" target="_blank" className="iconCircleButton">
              <i className="fab fa-instagram fa-fw"></i>
            </a>
            <a href="https://facebook.com/EveryNamecom-869744663045937" target="_blank" className="iconCircleButton">
              <i className="fab fa-facebook-f fa-fw"></i>
            </a>
            <a href="https://twitter.com/EveryNamecom/" target="_blank" className="iconCircleButton">
              <i className="fab fa-twitter fa-fw"></i>
            </a>
          </div>
          <h6>Copyright © 2020 EveryName.com</h6>
        </div>
      </div>
    </div>
  );
};
