import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

const theme = createTheme({
  typography: {
    fontFamily: `'Ubuntu', -apple-system, BlinkMacSystemFont,
Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
Droid Sans, Helvetica Neue, sans-serif`,
    color: '#596066',
  },
  palette: {
    primary: {
      main: '#92c954',
    },
    secondary: {
      main: '#92c954',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          color: '#596066',
          fontSize: '100%',
          lineHeight: 1.25,
        },
      },
    },
  },
});

export default theme;
