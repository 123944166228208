import * as React from 'react';
import { useRouter } from 'next/router';
import { isLocalDev } from '../../../environment';
import { useCurrentSite } from '../../context/CurrentSiteState';
import { AccessCodeModel } from '../accessCodeModel/AccessCodeModel';

export const LoginForm = () => {
  const router = useRouter();
  const { loginUsingAccessCode, loginUsingAccessCodeInProgress } = useCurrentSite();
  const [inputValues, setInputValues] = React.useState(['', '', '', '']);
  const inputsRef = React.useRef(new Map());
  const submitBtnRef = React.useRef();

  React.useEffect(() => {
    if (router && router.query.accessCode?.length > 0) {
      setInputValues(router.query.accessCode.split(''));
      loginUsingAccessCode(router.query.accessCode);
    }
  }, [router]);

  function handleInput(val, index) {
    const inputString = inputValues
      .toSpliced(index, val[0] ? 0 : 1, val[0])
      .filter((char) => char?.match(/[a-zA-Z0-9]/))
      .join('');
    setInputValues([...Array(4)].map((_, i) => inputString[i] || ''));
    inputsRef.current.get(inputString.length)?.focus();
  }

  async function handleLogin(e) {
    e.preventDefault();
    localStorage.setItem('seenWelcomeMessage', true);
    const accessCode = inputValues.join('').trim();
    if (accessCode.length !== 4) {
      return;
    }
    loginUsingAccessCode(accessCode);
  }

  return (
    <div className="login__form">
      <h1 className="textMargin">Access Code</h1>
      <form onSubmit={handleLogin}>
        <div className="accessCodeInputs">
          {inputValues.map((inputValue, i) => (
            <input
              key={i}
              ref={(node) => (node ? inputsRef.current.set(i, node) : inputsRef.current.delete(i))}
              className="input"
              type="text"
              value={inputValue}
              onChange={(e) => handleInput(e.target.value, i)}
              onKeyDown={(e) => {
                // if input is empty and backspace pressed, delete value of previous input
                e.key === 'Backspace' && inputValue.length === 0 && handleInput('', i > 0 ? i - 1 : 0);
              }}
            />
          ))}
          <button ref={submitBtnRef} className="submit iconButton--greenBg" type="submit">
            {(loginUsingAccessCodeInProgress && <i className="fa fa-spinner fa-fw fa-spin"></i>) || (
              <i className="fa fa-angle-right fa-fw"></i>
            )}
          </button>
        </div>
      </form>
      <AccessCodeModel />
    </div>
  );
};
