import React from 'react';
import styles from './Popover.module.css';
import PropTypes from 'prop-types';

export const Popover = (props) => {
  function closePopover() {
    props.onClose();
  }
  if (props.isVisible) {
    return props.onClose();
  }
  return (
    <div className="popover">
      <div className="popover" style={styles.popover}>
        <div className="popover__content">{props.children}</div>
      </div>
      <div className="popover__overlay" onClick={() => closePopover()}></div>
    </div>
  );
};

Popover.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.func,
  isVisible: PropTypes.bool,
};
