import { useEffect, useState } from 'react';
import Router, { useRouter } from 'next/router';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import queryString from 'query-string';
import { ALL_GROUPS } from '../customFilter';

export const StoreFilters = () => {
  const router = useRouter();
  const allGroupsQueryResult = useQuery(ALL_GROUPS);
  const [query, setQuery] = useState(queryString.parseUrl(router.asPath)?.query ?? {});

  useEffect(() => {
    setQuery(queryString.parseUrl(router.asPath)?.query ?? {});
  }, [router.asPath]);

  function updateQuery(query) {
    if (!query.filters) {
      delete query.filters;
    }
    setQuery(query);
    let url = queryString.stringifyUrl({
      url: router.asPath.split('?')[0],
      query,
    });

    Router.replace(router.pathname, url);
  }

  function handleResultsView(type) {
    updateQuery({ ...query, custom: type });
  }

  function handleFitlerGroupSelect(pk) {
    let filters = query.filters?.split('-') || [];

    if (filters.includes(pk.toString())) {
      filters = filters.filter((i) => pk.toString() !== i);
    } else {
      filters = [...filters, pk];
    }
    updateQuery({
      ...query,
      filters: filters.join('-'),
    });
  }

  function clearFilters() {
    updateQuery({ ...query, custom: undefined, filters: undefined });
  }

  return (
    <>
      <style jsx>{`
        .filter-count {
          height: 16px;
          border-radius: 8px;
          background-color: #e08d8f;
          font-size: 10px;
          font-weight: bold;
          text-align: center;
          color: #ffffff;
          line-height: 1;
          position: absolute;
          top: 10px;
          right: -18px;
          padding: 3px 5px;
        }
      `}</style>
      <div className="storeFilters">
        <div className="storeFilters__header">
          <h2 style={{ position: 'relative', padding: '1rem 0' }}>
            Filters
            {query.filters?.split('-').length > 0 && (
              <div className="filter-count">{query.filters?.split('-').length}</div>
            )}
          </h2>
          {query.filters?.split('-').length > 0 && (
            <button className="textButton" onClick={() => clearFilters()}>
              <i className="fas fa-times-circle"></i> Reset All
            </button>
          )}
        </div>
        <div className="storeFilters__content">
          <h3 className="textMargin">What to show</h3>
          <label className="radioContainer">
            Show all products
            <input
              type="radio"
              name="productsToShow"
              value="showAll"
              checked={!query.custom}
              onChange={() => handleResultsView()}
            />
            <span className="checkmark"></span>
          </label>
          <label className="radioContainer">
            Show only customizable products
            <input
              type="radio"
              name="productsToShow"
              value="showCustomizable"
              checked={query.custom === 'only'}
              onChange={() => handleResultsView('only')}
            />
            <span className="checkmark"></span>
          </label>
          <label className="radioContainer">
            Hide customizable products
            <input
              type="radio"
              name="productsToShow"
              value="hideCustomizable"
              checked={query.custom === 'none'}
              onChange={() => handleResultsView('none')}
            />
            <span className="checkmark"></span>
          </label>
          <h3 className="textMargin">Filter results by type</h3>

          {allGroupsQueryResult.data?.groups?.edges
            .map((i) => i.node)
            .map(({ name, id, pk }) => (
              <label key={id} className="checkboxContainer">
                {name}
                <input
                  type="checkbox"
                  checked={query.filters?.split('-').includes(pk.toString()) ?? false}
                  onChange={() => handleFitlerGroupSelect(pk)}
                />
                <span className="checkmark"></span>
              </label>
            ))}
        </div>
      </div>
    </>
  );
};
